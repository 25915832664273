exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3bOdSgNcY2q8o8_c_k7SHW{margin-top:10px;margin-bottom:10px}._1mfMYswvkkuH6Gy3S1IVbI{table-layout:fixed}._2vJq7g8az3io876mC4ByX1.alert{margin-bottom:10px}._2vJq7g8az3io876mC4ByX1 h4{font-size:16px;margin-bottom:.5em}._2vJq7g8az3io876mC4ByX1 ul{list-style:disc;margin:.5em 0}", ""]);

// exports
exports.locals = {
	"title": "_3bOdSgNcY2q8o8_c_k7SHW",
	"fixedTable": "_1mfMYswvkkuH6Gy3S1IVbI",
	"validationSummary": "_2vJq7g8az3io876mC4ByX1"
};